import i18n from '@/i18n';

const configFormTitleButton = {
    configFieldTitle: {
        isNumber: false,
        isMaxLength: false,
        error: false,
        errorField: i18n.t('landingPage.settingButtonBlock.titleBlock'),
        errorCustomMess: '',
        valueSync: '',
        typesValidate: ['required', 'max255']
    },
    configFieldTitleButton: {
        isNumber: false,
        isMaxLength: false,
        error: false,
        errorField: i18n.t('landingPage.settingButtonBlock.titleButton'),
        errorCustomMess: '',
        valueSync: '',
        typesValidate: ['max255']
    },
    configFieldLink: {
        isNumber: false,
        isMaxLength: false,
        error: false,
        errorField: i18n.t('landingPage.settingButtonBlock.link'),
        errorCustomMess: '',
        valueSync: '',
        typesValidate: ['max255', 'url']
    },
    configFieldColor: {
        type: 'color',
        classCustom: 'form-control-color',
        error: false
    }
};

const STATUS_ATTRIBUTE = [
    {
        id: 1,
        name: 'Vuông',
        value: 'square'
    },
    {
        id: 2,
        name: 'Tròn',
        value: 'circle'
    }
];

export { configFormTitleButton, STATUS_ATTRIBUTE };
