<template>
    <div>
        <div class="form-group row">
            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                >{{ $t('landingPage.settingButtonBlock.titleBlock') }} <span class="text-danger">*</span></label
            >
            <div class="col-sm-9">
                <InputText
                    :model.sync="dataFormTitle.title_block"
                    :config.sync="configFormTitleButton.configFieldTitle"
                    @blur="handleValidateField(configFormTitleButton.configFieldTitle)"
                />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingButtonBlock.titleButton') }} </label>
            <div class="col-sm-9">
                <InputText
                    :model.sync="dataFormTitle.title_button"
                    :config.sync="configFormTitleButton.configFieldTitleButton"
                    @blur="handleValidateField(configFormTitleButton.configFieldTitleButton)"
                />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingButtonBlock.link') }} </label>
            <div class="col-sm-9">
                <InputText
                    :model.sync="dataFormTitle.url"
                    :config.sync="configFormTitleButton.configFieldLink"
                    @blur="handleValidateField(configFormTitleButton.configFieldLink)"
                />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingButtonBlock.style') }} </label>
            <div class="col-sm-9">
                <Multiselect
                    :id="`select-block_button-style-${dataFormTitle?.id ?? dataFormTitle?.make_id}`"
                    :value.sync="dataFormTitle.style"
                    :options="LIST_STYLE_BUTTON_BLOCK"
                    :config="{ error: false, isRemove: true, loading: false, trackBy: 'id', label: 'name' }"
                />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingButtonBlock.color') }} </label>
            <div class="col-sm-2">
                <InputText
                    :model.sync="dataFormTitle.color"
                    :config.sync="configFormTitleButton.configFieldColor"
                    @blur="handleValidateField(configFormTitleButton.configFieldColor)"
                />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingButtonBlock.attribute') }} </label>
            <div class="col-sm-9">
                <div class="d-flex">
                    <div v-for="item in STATUS_ATTRIBUTE" :key="item.id + 's'">
                        <InputRadio
                            class="mr-4"
                            :model.sync="dataFormTitle.attribute"
                            :name="`attribute`"
                            :id="`attribute-${item.id}`"
                            :value="item.value"
                            :label="item.name"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center" style="gap: 1rem">
            <button class="btn btn-light" @click="handleCancel">{{ $t('btn.cancel') }}</button>
            <button class="btn btn-success" @click="handleSave">{{ $t('btn.save') }}</button>
        </div>
    </div>
</template>

<script>
import { configFormTitleButton, STATUS_ATTRIBUTE } from './constants';
import { InputText, InputRadio } from '@/components/Input';
import { validateField, validateAllField } from '@/utils/validate';
import { LIST_STYLE_BUTTON_BLOCK } from '../../constants';

import Multiselect from '@/components/Multiselect/main.vue';

export default {
    name: 'ActionButton',
    components: {
        InputText,
        InputRadio,
        Multiselect
    },
    props: {
        dataTitle: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            configFormTitleButton: configFormTitleButton,
            LIST_STYLE_BUTTON_BLOCK: LIST_STYLE_BUTTON_BLOCK,
            STATUS_ATTRIBUTE: STATUS_ATTRIBUTE,
            dataFormTitle: {
                title_block: '',
                title_button: '',
                url: '',
                style: null,
                color: '',
                attribute: STATUS_ATTRIBUTE[0].value
            }
        };
    },
    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },
        handleCancel() {
            let { configFieldTitle, configFieldTitleButton, configFieldLink } = this.configFormTitleButton;
            configFieldTitleButton.error = configFieldLink.error = configFieldTitle.error = false;
            this.$emit('cancel');
        },

        handleSave() {
            let checkValidate = validateAllField(configFormTitleButton);
            if (!checkValidate) return;
            if (this.dataFormTitle?.id || (this.dataFormTitle?.make_id && this.dataFormTitle?.typeAction === 'Edit')) {
                this.$emit('updateData', this.dataFormTitle);
            } else {
                this.dataFormTitle.typeAction = 'Edit';
                this.$emit('addData', this.dataFormTitle);
            }
        }
    },
    watch: {
        dataTitle: {
            handler: function (newTitle) {
                this.dataFormTitle = {
                    ...this.dataFormTitle,
                    ...newTitle,
                    style: LIST_STYLE_BUTTON_BLOCK.find((item) => item.id === newTitle?.style || item.id === newTitle?.style?.id)
                };
            },
            immediate: true
        }
    },
    mounted() {}
};
</script>

<style lang="scss" scoped></style>
